.graph-button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-block:1em;
  padding-inline: 0.5em;
  border-radius: 0.2em;
  background: #211e28;
  border: none;
  outline: none;
  min-width: 1.75em;
  height: 1.56em;

  color: #fff;
  font-size: 0.5em;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  opacity: 0.5;

  &-active {
    background: #0156fc;
    opacity: 1;
  }

  @media only screen and (min-width: 1025px) {
    font-size: 0.8em;
  }
}

.duration-buttons {
  display: flex;
  flex-direction: row;
  gap: 0.5em;
}
