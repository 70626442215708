* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Poppins", monospace, Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", monospace, sans-serif;
}
.app {
  min-height: 100vh;
  background-color: #000610;
  overflow-x: hidden;
  position: relative;
  .footer {
    @media only screen and (min-width: 900px) {
      padding: 2em;
    }
  }
}

.navBbar {
  @media only screen and (max-width: 900px) {
    padding-inline: 1em;
  }
}

.pageHeading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 3em;
  padding-top: 2em;
  color: white;
  position: relative;
  &-top {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    gap: 1em;
  }
}

.pageTitle {
  font-size: 1.3em;
  @media only screen and (min-width: 1000px) {
    font-size: 3.2em;
  }
}

.description {
  font-size: 0.6em;
  opacity: 0.8;
  @media only screen and (min-width: 1000px) {
    width: 56ch;
    font-size: 1.2em;
  }
}

.pageSubTitle {
  font-size: 1.2em;
  font-weight: 400;
  @media only screen and (min-width: 1000px) {
    font-size: 3em;
  }
}
