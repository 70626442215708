.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-block: 1em;
  position: relative;
  top: 0;
  right: 0;
  left: 0;
  padding-inline: 0.5em;
  @media only screen and (min-width: 767px) {
    padding-inline: 3em;
  }
  &-nav {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 1.2em;
    &-link {
      color: #fff;
      font-size: 1em;
      font-style: normal;
      font-weight: 300;
      text-decoration: none;
      &-disabled {
        -moz-user-select: none;
        -webkit-user-select: none;
        user-select: none;
        pointer-events: none;
      }
    }
  }
}

.hideMobile {
  @media only screen and (max-width: 999px) {
    display: none;
  }
}
