.graphCards {
  position: relative;
  padding-inline: 0.5em;
  @media only screen and (min-width: 1000px) {
    padding-inline: 2em;
  }
}
.graphs {
  display: grid;
  grid-template-rows: 1fr;
  gap: 1em;
  margin-block: calc(var(1em) * 1);
  grid-template-columns: 1fr;
  padding-inline: calc(var(1em) * 1);
  @media only screen and (min-width: 800px) and (max-width: 1000px) {
    padding-inline: calc(var(1em) * 1);
    grid-template-columns: repeat(2, 1fr);
  }

  @media only screen and (min-width: 1000px) {
    grid-template-columns: repeat(2, 1fr);
    padding-inline: calc(var(1em) * 2.4);
  }
}

.fullWidthGraph {
  @media only screen and (min-width: 800px) {
    grid-column: span 2;
  }
}
