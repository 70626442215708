$content_margin: 2em;

@media only screen and (min-width: 1025px) {
  $content_margin: 4em;
}

.footer {
  position: relative;
  padding-block: 2em;
  color: white;
  opacity: 0.8;
  background: rgba(0, 6, 16, 0.1);
  box-shadow: 0.4em 0.4em 0.8em 0em rgba(0, 0, 0, 0.04);
  height: 100%;

  &Container {
    display: flex;
    flex-direction: column-reverse;
    height: 100%;
    gap: 4vw;
    @media only screen and (min-width: 1000px) {
      flex-direction: row;
      align-items: center;
    }
    &Left {
      @media only screen and (min-width: 1000px) {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 2.4em;
        flex: 1;
        &SocialLinks {
          padding-inline: 0.5em;
        }
      }
      @media only screen and (max-width: 1000px) {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding-inline: 0.5em;

        &SocialLinks {
          width: 100%;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-end;
        }
      }
    }

    &Right {
      @media only screen and (max-width: 999px) {
        padding-inline: calc($content_margin / 2);
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: 1fr;
        gap: 2em;
        margin-bottom: 2em;
      }
      @media only screen and (min-width: 1000px) {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;
        width: 50vw;
      }
      &Community {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        gap: 1.2em;
        &Title {
          font-size: 1em;
          color: #ffffff;
          cursor: auto;
          @media only screen and (min-width: 1000px) {
            font-size: 1.5em;
          }
        }
        &Links {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 1em;
        }
        &Link {
          font-size: 0.6em;
          color: #b3b3b3;
          text-decoration: none;
          @media only screen and (min-width: 1000px) {
          font-size: 0.9em;

          }
        }
      }
    }
  }
}
