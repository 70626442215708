.aggregatedCards {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 1fr;
  gap: 1em;
  padding-inline: 2em;
  padding-block: 2em;
  @media only screen and (max-width: 1000px) {
    grid-template-columns: repeat(2, 1fr);
    gap: 0.3em;
    padding-inline: 0.5em;
  }
}

.aggregatedValue {
  font-size: 1.1em;
  @media only screen and (min-width: 350px) and (max-width: 700px) {
    font-size: 1.8em;
  }
  @media only screen and (min-width: 700px) {
    font-size: 2em;
    
  }
}
