.social {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  a {
    padding-inline: 0.5em;
  }
  @media only screen and (min-width: 1000px) {
    justify-content: space-between;
    width: 12em;
    a {
      padding-inline: 0.5em;
    }
  }
}
