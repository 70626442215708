.banner {
  position: absolute;
  top: 4em;
  left: 50%;
  transform: translate(-50%, 0);
  height: auto;
  width: 90%;
  pointer-events: none;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  @media only screen and (min-width: 1000px) {
    top: 6em;
  }
}
