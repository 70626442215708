.card {
  min-height: 6em;
  min-width: calc(25% - 4em);
  color: white;
  border-radius: 1em;
  border: 1pt solid #222a38;
  position: relative;
  overflow: hidden;
  background: rgba(00, 06, 10);
  box-shadow: 0.4em 0.4em 0.8em 0em rgba(0, 0, 0, 0.04);
  &-container {
    height: 100%;
    padding-block: 1.2em;
    width: 100%;
  }

  &-graph {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: 2ch auto;
    gap: 1.2em;
    padding-inline: 1.4em;

    &-top {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
    &-bottom {
      display: flex;
      align-items: center;
      justify-content: center;
      height: calc(16px * 22);
      max-width: calc(50vw - calc(1em * 4));
      @media only screen and (max-width: 800px) {
        max-width: calc(100vw - calc(1em * 2));
      }
      &-full {
        max-width: calc(100vw - calc(1em * 2));
      }
    }
    &-loader {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &-title {
      color: #ffffff;
      font-size: 0.7em;
      opacity: 0.8;
      @media only screen and (min-width: 1000px) {
        font-size: 1.2em;
      }
    }
  }

  &-small {
    display: flex;
    flex-direction: column;
    gap: 1em;
    &-top {
      display: flex;
      flex-direction: row;
      justify-content: center;
      text-align: center;
      opacity: 0.8;
      font-size: 1.1em;
      p {
        @media only screen and (max-width: 700px) {
          opacity: 0.8;
          font-size: max(10px, 0.64em);
        }
      }
    }
    &-bottom {
      display: flex;
      flex-direction: row;
      justify-content: center;
    }
  }
}
